import React, { ReactNode } from 'react';

import { cn } from '@ui/uikit/utils';

import { MarkInfo } from '../../icons/mono';
import { TooltipContentProps, TooltipProps } from './Tooltip';
import { Tooltip } from './Tooltip';

export type InfoTooltipProps<Content = ReactNode> = Omit<TooltipProps, 'trigger'> & {
  triggerClassName?: string;
} & TooltipContentProps<Content>;

export const InfoTooltip = ({ triggerClassName, ...props }: InfoTooltipProps) => {
  return (
    <Tooltip
      {...(props as TooltipProps)}
      trigger={
        <button type="button" className={cn('tooltip-button tooltip-button-a', triggerClassName)}>
          <MarkInfo className="tooltip-icon" />
        </button>
      }
    />
  );
};
