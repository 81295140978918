const formatUsername = (
  { magicId, displayName, name }: { magicId?: string; displayName?: string; name?: string } = {},
  placeholder?: string,
) => {
  // Same property could be used in different fields based on the API version (new | old)
  // It's much easier to handle it here
  if (displayName || name) {
    return displayName || name;
  }

  if (magicId) {
    return magicId;
  }

  return placeholder ?? 'no-name';
};

export default formatUsername;
